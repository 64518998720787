export class Carrier {
  refCarrierID: number;
  carrierName: string;
  carrierCode: string;
  scac: string;
  rating: number;
  isDynamicPricing: boolean;
  bypassSMC: boolean;
  isExcludedFromAllBlanketDefault: boolean;
  isApplyOVDToHeight: boolean;
  isApplyOVDByPiece: boolean;
  public constructor(init?: Partial<Carrier>) {
    Object.assign(this, init);
  }

  public static humanFriendlyCarrierString(carrier: Carrier, isBgEmployee: boolean): string {
    if (carrier)
    {
      let textToDisplay = carrier.scac ? carrier.scac : 'Missing SCAC';

      if (isBgEmployee && carrier.carrierCode && carrier.carrierCode.trim()) {
        textToDisplay += ` (${carrier.carrierCode})`;
      }

      return textToDisplay;
    }

    return '';
  }
}
