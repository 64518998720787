import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BooleanIndicatorComponent } from "./boolean-indicator/boolean-indicator.component";
import { CustomerSearchComponent } from "./customer-search/customer-search.component";
import { CustomerHierarchyComponent } from "./customer-hierarchy/customer-hierarchy.component";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ToastrModule } from "ngx-toastr";
import { LoaderComponent } from './loader/loader.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { GridModule } from "@progress/kendo-angular-grid";
import { TreeListModule } from "@progress/kendo-angular-treelist";
import { CarrierDropdownComponent } from "./carrier-dropdown/carrier-dropdown.component";
import { CarrierMultiselectComponent } from "./carrier-multiselect/carrier-multiselect.component";

@NgModule({
  declarations: [
    CustomerSearchComponent,
    CustomerHierarchyComponent,
    LoaderComponent,
    AuditHistoryComponent,
    BooleanIndicatorComponent,
    CarrierDropdownComponent,
    CarrierMultiselectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    ToastrModule.forRoot(),
    DialogModule,
    GridModule,
    TreeListModule,
  ],
  exports: [
    CustomerSearchComponent,
    CustomerHierarchyComponent,
    LoaderComponent,
    AuditHistoryComponent,
    BooleanIndicatorComponent,
    CarrierDropdownComponent,
    CarrierMultiselectComponent,
  ],
})
export class SharedModule {}
