import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Carrier } from "src/app/models/Carrier";
import { AuthService } from "../../services/auth.service";
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns/dist/es2015/dropdownlist.component";
import { CustomerAccessorialOverrideRule } from "../../models/CustomerAccessorialOverrideRule";
import { timer } from "rxjs";

@Component({
  selector: "app-carrier-dropdown",
  templateUrl: "./carrier-dropdown.component.html",
  styleUrls: ["./carrier-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarrierDropdownComponent),
      multi: true
    }
  ]
})
export class CarrierDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() required: boolean;
  @Input() carriers: Carrier[];
  @Input() styles: any;

  @Output() valueChange = new EventEmitter<number>();

  @ViewChild("dropdownlist", { static: true }) public dropdownlist: DropDownListComponent;

  filteredCarriers: Carrier[];
  isBgEmployee: boolean = false;
  refCarrierID: number = null;
  loadingCarriers: boolean = false;
  loadingCarriersAttempts: number = 0;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isBgEmployee = this.authService.isBluegraceEmployee();

    if (!this.carriers || this.carriers.length <= 0) {
      this.timeoutForCarriers();
    }
  }

  timeoutForCarriers() {
    if (this.loadingCarriersAttempts > 30) {
      console.log('Failed to load Carriers. Check that the "carriers" parameter is correctly set and providing an array or a valid async array.')
      return;
    }

    this.loadingCarriers = true;
    this.loadingCarriersAttempts++;
    timer(100).subscribe(() => {
      if (!this.carriers || this.carriers.length <= 0) {
        this.timeoutForCarriers();
      }
      else {
        this.filteredCarriers = this.carriers.slice();
        this.loadingCarriers = false;
      }
    });
  }

  selectionChanged(event: Carrier) {
    this.onChange(event.refCarrierID);
    this.onTouched();

    if (!!this.valueChange) {
      this.valueChange.emit(event.refCarrierID);
    }
  }

  displayHumanFriendlyCarrierString(carrier: Carrier): string {
    return Carrier.humanFriendlyCarrierString(carrier, this.isBgEmployee);
  }

  handleFilter(value: string) {
    if (value && value.length > 0) {
      const loweredValue = value.toLowerCase();

      this.filteredCarriers = this.carriers.filter(
        (x) => (x.scac && x.scac.toLowerCase().indexOf(loweredValue) !== -1) ||
          (x.carrierCode && x.carrierCode.toLowerCase().indexOf(loweredValue) !== -1)
      );
    }
    else {
      this.filteredCarriers = this.carriers.slice();
    }
  }


  /*
  * ControlValueAccessor implementations
  */
  disabled = false;
  onTouched: any;
  onChange: any;
  writeValue(refCarrierID: number): void {
    this.dropdownlist.reset();
    this.refCarrierID = refCarrierID;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /*
  * End of: ControlValueAccessor implementations
  */
}
