import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Carrier } from "src/app/models/Carrier";
import { AuthService } from "../../services/auth.service";
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns/dist/es2015/multiselect.component";

@Component({
  selector: "app-carrier-multiselect",
  templateUrl: "./carrier-multiselect.component.html",
  styleUrls: ["./carrier-multiselect.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarrierMultiselectComponent),
      multi: true
    }
  ]
})
export class CarrierMultiselectComponent implements OnInit , ControlValueAccessor {
  @Input() required: boolean;
  @Input() carriers: Carrier[];

  @Output() valueChange = new EventEmitter<number[]>();

  @ViewChild("multiselect", { static: true }) public multiSelect: MultiSelectComponent;

  isBgEmployee: boolean = false;
  refCarrierIDs: number[] = null;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isBgEmployee = this.authService.isBluegraceEmployee();
  }

  valueChanged(event: number[]) {
    this.onChange(event);
    this.onTouched();

    if (!!this.valueChange) {
      this.valueChange.emit(event);
    }
  }

  displayHumanFriendlyCarrierString(carrier: Carrier): string {
    return Carrier.humanFriendlyCarrierString(carrier, this.isBgEmployee);
  }


  /*
  * ControlValueAccessor implementations
  */
  disabled = false;
  onTouched: any;
  onChange: any;
  writeValue(refCarrierIDs: number[]): void {
    this.multiSelect.reset();
    this.refCarrierIDs = refCarrierIDs;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /*
  * End of: ControlValueAccessor implementations
  */
}
